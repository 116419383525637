import React from "react";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

import programImage from "./assets/program_interface.png";

export const ShaderEvaluator = () => {
  return (
    <div className="text-base bg-gray-50 p-8 mt-12 md:mt-0">
      <div className=" justify-between w-full h-12 hidden md:flex">
        <h2 className="text-base font-thin  text-gray-500 py-2 text-center">
          Graphics Programming
        </h2>
        <Link
          className="text-gray-500 inline-block pb-2 z-20 font-light hover:font-normal hover:text-black"
          to={"/projects"}
        >
          <BiArrowBack className="inline-block align-middle pb-1" />
          <p className="inline-block m-1">Back</p>
        </Link>
      </div>
      <h1 className="block font-medium text-4xl mt-1 mb-8">Shader Evaluator</h1>
      <p className="leading-6 mb-3">
        I made this application as part of a introductory course in Computer
        Graphics, I took it as a good opportunity to get more practice with C++
        and OpenGL. The purpose of the application was to compare common shading
        algorithms and to understand the difference between them. I created a
        Lambert, Phong, Blinn-Phong, and a Oren Nayar-shader.
      </p>
      <div className={"flex flex-col max-w-xl h-auto m-auto"}>
        <img
          src={programImage}
          alt={"Interface with shader controls and spherical shader preview"}
          className={"object-cover mt-8"}
        />
        <p className="text-gray-500 italic mt-3 mb-8 text-center">
          The shader evaluator interface with multiple parameters to experiment with. 
        </p>
      </div>
      <p>
        The experience of developing GUI applications in C++ was interesting and
        a lot different compared to my experience with GUI programming in other
        languages. I decided to use a framework called Dear ImGui, which proven
        to be a very easy framework to get started with. I also used the
        libraries GLAD, GLFW, and GLM for access to a rendering context and the
        OpenGL functions. The project is{" "}
        <a
          href={"https://github.com/GabrielRosenberg/Shader-evaluator"}
          className={"text-blue-500 hover:underline"}
        >
          available at GitHub
        </a>{" "}
        , feel free to take a look or implement your own shaders to compare.
      </p>
    </div>
  );
};
