import React from "react";
import albaNovaThumbnail from "../components/portfolioItems/AlbaNovaViz/assets/thumbnailAlbaNova.jpg";
import rayTracingThumbnail from "../components/portfolioItems/pathTracing/assets/rayTracing.png";
import cnnFacesThumbnail from "../components/portfolioItems/cnnFaces/assets/cnnimage.png";
import shaderEvaluatorThumbnail from "../components/portfolioItems/shaderEvaluator/assets/thumbnailImage.png";

import Project from "./Project";


//import './Projects.css';

export const Projects = () => {

  return (
    <div
      className="projects grid grid-cols-2 gap-3 p-3 
    sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-16 md:mt-0"
    >
      <Project thumbnailImage={albaNovaThumbnail} category={'Modelling & Rendering'} title={'Architectural Visualization of AlbaNova'} adress={'/AlbaNovaViz'}/>
      <Project thumbnailImage={rayTracingThumbnail} category={'Graphics programming'} title={'Path tracing with Cook-Torrance BRDF'} adress={'/glslRayTracing'}/>
      <Project thumbnailImage={cnnFacesThumbnail} category={'Deep learning'} title={'Colorizing Grayscale Images'} adress={'/imageColorization'}/>
      <Project thumbnailImage={shaderEvaluatorThumbnail} category={'Graphics Programming'} title={'Shader evaluator'} adress={'/shaderEvaluator'}/>

    </div>
  );
};
