import React from "react";
import { useHistory } from "react-router-dom";

const Project = ({ thumbnailImage, category, title, adress }) => {
  const history = useHistory();
  const handleClick = () => history.push(adress);

  return (
    <div
      className="portfolioThumbnail group relative m-1 overflow-hidden cursor-pointer max-h-48"
      onClick={handleClick}
    >
      <div
        className="overlay absolute top-0 left-0 w-full h-full 
        flex flex-col justify-center z-10 
        bg-black bg-opacity-0 text-black text-opacity-0 transition-all ease-in-out duration-300
        group-hover:bg-opacity-40 group-hover:text-opacity-100 group-hover:text-white"
      >
        <span className="description m-3 font-normal text-lg text-center">
          {title}
        </span>
        <span className="category font-light text-base text-center">
          {category}
        </span>
      </div>
      <div className='flex'>
        <img
          className="duration-300 justify-center w-full object-cover"
          src={thumbnailImage}
          alt={title}
        />
      </div>
    </div>
  );
};

export default Project;
