import { React, useState } from "react";
import Favicon from "react-favicon";
import faviconImage from "./favicon.png";
import { Sidebar } from "./components/Sidebar";
import { Projects } from "./components/Projects";
import { About } from "./components/About";
import { AlbaNovaViz } from "./components/portfolioItems/AlbaNovaViz/AlbaNovaViz";
import { CnnFaces } from "./components/portfolioItems/cnnFaces/CnnFaces";
import { PathTracing } from "./components/portfolioItems/pathTracing/PathTracing";
import { ShaderEvaluator } from "./components/portfolioItems/shaderEvaluator/ShaderEvaluator";

import useWindowDimensions from "./components/UseWindowDimensions";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

const App = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const { currentWidth, previousWidth } = useWindowDimensions();

  if (currentWidth >= 768 && previousWidth < 768 && mobileMenu)
    setMobileMenu(false);

  return (
    <Router>
      <div className="w-full h-full relative">
        <Favicon url={faviconImage} />
        <div className="h-full md:ml-72 bg-gray-50">
          <Switch>
            <Route path="/" exact component={Projects} />
            <Route path="/projects">
              <Projects />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/AlbaNovaViz">
              <AlbaNovaViz />
            </Route>
            <Route path="/imageColorization">
              <CnnFaces />
            </Route>
            <Route path="/glslRayTracing">
              <PathTracing />
            </Route>
            <Route path="/shaderEvaluator">
              <ShaderEvaluator />
            </Route>
          </Switch>
        </div>
        <Sidebar mobileMenu={mobileMenu} setMobileMenu={setMobileMenu}/>
      </div>
    </Router>
  );
};

export default App;
