import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import profileImage from "./common/assets/Gabriel_Rosenberg_square.jpg";

export const About = () => {
  return (
    <div className="m-auto p-8">
      <div className="flex justify-between w-full h-12">
        <h2 className="text-base font-thin  text-gray-500 py-2 text-center">
          About me
        </h2>
        <Link
          className="text-gray-500 inline-block pb-2 z-10 font-light hover:font-normal hover:text-black"
          to={"/projects"}
        >
          <BiArrowBack className="inline-block align-middle pb-1" />
          <p className="inline-block m-1">Back</p>
        </Link>
      </div>
      <p className="text-2xl mt-2 mb-8">
        Hello there!{" "}
        <span className="font-light">
          My name is Gabriel Rosenberg, I’m a master’s student in Computer
          Science at KTH in Stockholm, Sweden.
        </span>
      </p>
      <div className={"flex flex-col sm:flex-row gap-4"}>
        <div className={"max-w-80 max-h-80 overflow-hidden sm:order-1 flex"}>
          <img
            src={profileImage}
            alt={"Portrait of myself"}
            className={"justify-center w-full object-cover"}
          />
        </div>
        <div className={"text-base"}>
          <p className={"mb-3"}>
            My fascination for digital imagery was sparked in 5th grade when I
            got my first DSLR camera. I was amazed to see how easy it was to
            create videos with so simple equipment. Soon, I started to explore
            the fields of VFX and CGI that later led me to find my passion for
            programming and computer graphics. Currently, I focus on courses
            about computer graphics programming and machine learning because I
            believe that this magical combination will revolutionize the
            possibilities of digital imagery.
          </p>
          <p className={"mb-3"}>
            I have created this website to share work that I have done and
            projects I am proud of. But this is also a platform for me to
            explore new programming frameworks and technologies, perhaps even to
            learn someone else something along the way. If you have any
            feedback, questions or want to contact me, feel free to{" "}
            <a href="mailto:grosenb@kth.se" className={"z-50"}>
              send me an email
            </a>{" "}
            and I will get back to you!
          </p>
        </div>
      </div>
    </div>
  );
};
