import React from "react";
import { Link } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";

import pathTracingImage from "./assets/wide_path_tracing.png";
import projectReport from "./assets/glsl_ray_tracing.pdf";

export const PathTracing = () => {
  return (
    <div className="text-base bg-gray-50 p-8 mt-12 md:mt-0">
      <div className=" justify-between w-full h-12 hidden md:flex">
        <h2 className="text-base font-thin  text-gray-500 py-2 text-center">
          Graphics Programming
        </h2>
        <Link
          className="text-gray-500 inline-block pb-2 z-20 font-light hover:font-normal hover:text-black"
          to={"/projects"}
        >
          <BiArrowBack className="inline-block align-middle pb-1" />
          <p className="inline-block m-1">Back</p>
        </Link>
      </div>
      <h1 className="block font-medium text-4xl mt-1 mb-8">
        Path tracing with Cook-Torrance BRDF
      </h1>
      <p className="leading-6 mb-3">
        Ray tracing in computer graphics is something that have fascinated me
        for may years, and in this project, me and my classmate, explored the
        fundamentals of ray tracing by implementing a ray tracer in GLSL. To
        write such a program in a shading language required us to build every
        part of the program from the ground up. Creating a rendering application
        from scratch was a educating experience since it required that we
        understood every step of the rendering process. For doing this we used a
        great shader developing tool called ShaderToy. This enabled us to focus
        on the task at hand without having to deal with any environment setup.
        Our program is{" "}
        <a href={projectReport} className={"text-blue-500 hover:underline"}>
          available at ShaderToy
        </a>{" "}
        , and can be tested by anyone.
      </p>
      <div className={""}>
        <img
          src={pathTracingImage}
          alt={"Cornell box with colorful spheres"}
          className={"object-cover w-full h-96 mt-8"}
        />
        <p className="text-gray-500 italic mt-3 mb-8 text-center">
          Our own Cornell Box-scene Rendered with our program in ShaderToy.
        </p>
      </div>
      <p className="leading-6 mb-3">
        Our goal in this project was to create renderer with Physically Based
        Rendering (PBR) methods. To do this, we implemented Cook-Torrance BRDF
        for realistic surface reflections together with a distributed ray
        tracing approach. We generated a cornell box-scene with plane and sphere
        geometry to test our application. Read our{" "}
        <a href={projectReport} className={"text-blue-500 hover:underline"}>
          full report here
        </a>{" "}
        to learn more about how we created this program.
      </p>
    </div>
  );
};
