import {useEffect, useState} from "react";

let prevWidth = 0

const getWindowDimensions = () => {
  const currentWidth = window.innerWidth;
  const previousWidth = prevWidth;

  prevWidth = currentWidth;
  return {
    currentWidth,
    previousWidth
  };
}

const useWindowDimensions = () => {
  const [windowWidth, setWindowWidth] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
}

export default useWindowDimensions;