import React from "react";
import referenceImage from "./assets/reference_image.JPG";
import render1 from "./assets/renderFinal1.jpg";
import render2 from "./assets/secondImage.jpg";
import renderWireframe1 from "./assets/1_wireframe.jpg";
import renderWireframe2 from "./assets/2_wireframe.png";
import video from "./assets/animation.mp4";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import VideoPlayer from "react-simple-video-player";
import useWindowDimensions from "../../UseWindowDimensions";

export const AlbaNovaViz = () => {
  const { currentWidth, previousWidth } = useWindowDimensions();

  const videoPlayerWidth = currentWidth > 768 ? currentWidth / 2 : currentWidth - 80

  return (
    <div className="text-base bg-gray-50 p-8 mt-12 md:mt-0">
      <div className=" justify-between w-full h-12 hidden md:flex">
        <h2 className="text-base font-thin  text-gray-500 py-2 text-center">
          Modeling & Rendering
        </h2>
        <Link
          className="text-gray-500 inline-block pb-2 z-20 font-light hover:font-normal hover:text-black"
          to={"/projects"}
        >
          <BiArrowBack className="inline-block align-middle pb-1" />
          <p className="inline-block m-1">Back</p>
        </Link>
      </div>
      <h1 className="block font-medium text-4xl mt-1 mb-8">
        Architectural Visualization of AlbaNova
      </h1>
      <p className="leading-6 mb-3">
        During the spring of 2020 I worked together with a project group at KTH
        in Stockholm. Our mission was to design interactive installations for
        the Swedish pavilion at Expo 2020 Dubai. By using state-of-the-art
        visualization and interaction technology, our goal was to engage the
        audience in climate research. My part in this project was to lead the
        development of 3D assets and environments.
      </p>
      <p>
        Because we couldn't evaluate our prototypes in the same environment as
        they would be used in, we modeled some of the environments in 3D so that
        we instead could test our prototypes in virtual reality. For this
        purpose, I modeled and textured a part of the research facility
        AlbaNova.
      </p>
      <div className="max-w-6xl flex flex-row gap-3 mt-10">
        <div className="max-w-full max-h-full">
          <img
            src={render1}
            alt={"Rendered view of the AlbaNova entrance"}
            className=""
          />
        </div>
        <div className="object-contain max-h-full max-w-full">
          <img
            src={render2}
            alt={"Rendered view of the AlbaNova entrance"}
            className=""
          />
        </div>
      </div>
      <p className="text-gray-500 italic mt-3 mb-5 text-center">
        Renders of AlbaNova's eastern entrance.
      </p>
      <p>
        All models in this project were made and textured with Blender, the
        images were rendered with Cycles. Photoshop was also used for some final
        compositing. I modeled the environment based on images taken during
        previous events in the building and I used an image of the floor plan to
        get the initial measures correct.
      </p>
      <p>
        A challenge in this project was not only to create a realistic
        visualization but also realistic to the real place. To work with correct
        measurements was hard and I mainly focused on getting the proportions
        right, although it was a great opportunity for learning how to extract
        object measures from images.
      </p>
      <div className="max-w-sm m-auto mt-10">
        <img
          src={referenceImage}
          alt={"Reference photograph of the AlbaNova entrance"}
          className=""
        />
      </div>
      <p className="text-gray-500 italic mt-3 mb-5 text-center">
        One of the reference images used for modeling and texturing.
      </p>
      <p>
        It was interesting to experiment with the placement of objects in the
        scene, the wide flat surfaces made a contrast to the interior and the
        detailed entrance. I tried to use this contrast to focus the viewers'
        interest towards the center of the image. The scene graph was quite
        large in the final stage of the project and it truly became a practice
        in scene organization.
      </p>

      <div className="mt-10 m-auto text-center">
        <VideoPlayer url={video} width={videoPlayerWidth} loop />
        <p className="text-gray-500 italic mt-3 mb-5 text-center">
          A viewport animation of the scene inside of Blender.
        </p>
      </div>

      <div className="max-w-6xl flex flex-row gap-3 mt-10">
        <div className="max-w-full max-h-full">
          <img
            src={renderWireframe1}
            alt={"Wireframe render of the AlbaNova entrance"}
            className=""
          />
        </div>
        <div className="object-contain max-h-full max-w-full">
          <img
            src={renderWireframe2}
            alt={"Wireframe render of the AlbaNova entrance"}
            className=""
          />
        </div>
      </div>
      <p className="text-gray-500 italic mt-3 mb-5 text-center">
        Viewport and wireframe renders.
      </p>
    </div>
  );
};
