import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

//import './Sidebar.css';
const MenuIcon = ({ toggleMenu }) => {
  if (!toggleMenu) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M4 6h16M4 12h16M4 18h16"
        />
      </svg>
    );
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    );
  }
};

export const Sidebar = ({mobileMenu, setMobileMenu}) => {
  return (
    <>
      <button
        onClick={() => setMobileMenu(!mobileMenu)}
        className="fixed right-6 top-5 ml-auto z-50 md:hidden"
      >
        <MenuIcon toggleMenu={mobileMenu} />
      </button>
      <nav
        className={`fixed inset-y-0 right-0 w-1/2 max-w-xs bg-gray-200 z-40 transform-gpu md:translate-x-full ${
          mobileMenu ? "translate-x-0" : "translate-x-full"
        } transition-transform ease-in-out duration-700 md:transition-none
      md:bg-transparent md:-left-80 md:top-1/3 md:inset-y-auto`}
      >
        <ul className="flex flex-col mt-4 ml-4">
          <li className="group text-lg m-2 w-44 overflow-hidden">
            <Link className="block w-full" to="/projects" onClick={() => setMobileMenu(false)}>
              Portfolio
            </Link>
            <span className="block h-1 w-11 bg-blue-500 transition-transform transform -translate-x-11 group-hover:translate-x-0 duration-300" />
          </li>
          <li className="group text-lg m-2 w-44 overflow-hidden">
            <Link className="block w-full" to="/about" onClick={() => setMobileMenu(false)}>
              About
            </Link>
            <span className="block h-1 w-11 bg-blue-500 transition-transform transform -translate-x-11 group-hover:translate-x-0 duration-300" />
          </li>
        </ul>
      </nav>
      <div
        className={`absolute inset-0 bg-black transition-opacity ease-in-out z-30 duration-700 md:opacity-0 md:transition-none ${
          mobileMenu ? "opacity-50" : "opacity-0 hidden"
        }`}
        onClick={() => setMobileMenu(false)}
      ></div>
      <div
        className="fixed flex flex-row h-16 w-full inset-x-0 top-0 bg-white z-30
        md:h-screen md:flex-col md:inset-y-0 md:gap-y-24 md:w-72"
      >
        {/* <div id={menuToggle ? "panelScrim" : ""}/> */}
        <div className="md:my-7 my-2 ml-6 md:self-start">
          <Link to={'/projects'}>
            <h1 className="text-3xl font-bold font-sans text-black hidden md:block">
              gabriel
              <br />
              <span id="logoDot">.</span>graphics
            </h1>
          </Link>

          <h1 className="text-xl mb-1 mt-3 block md:hidden font-medium">
            gabriel<span id="logoDot">.</span>graphics
          </h1>
        </div>
      </div>
    </>
  );
};
