import React, { useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Document, Page } from "react-pdf/dist/umd/entry.webpack";
import reportPdf from "./assets/Colorizing_black_and_white_images.pdf";

import in1 from "./assets/imagesComparison/in1.jpg";
import in2 from "./assets/imagesComparison/in2.jpg";
import in3 from "./assets/imagesComparison/in3.jpg";
import in4 from "./assets/imagesComparison/in4.jpg";
import out1 from "./assets/imagesComparison/out1.png";
import out2 from "./assets/imagesComparison/out2.png";
import out3 from "./assets/imagesComparison/out3.png";
import out4 from "./assets/imagesComparison/out4.png";

export const CnnFaces = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const imageStyle = "object-cover w-40 h-40";
  const imageContainerStyle = "m-auto flex justify-center";

  return (
    <div className="text-base bg-gray-50 p-8 mt-12 md:mt-0">
      <div className=" justify-between w-full h-12 hidden md:flex">
        <h2 className="text-base font-thin  text-gray-500 py-2 text-center">
          Deep learning
        </h2>
        <Link
          className="text-gray-500 inline-block pb-2 z-20 font-light hover:font-normal hover:text-black"
          to={"/projects"}
        >
          <BiArrowBack className="inline-block align-middle pb-1" />
          <p className="inline-block m-1">Back</p>
        </Link>
      </div>
      <h1 className="block font-medium text-4xl mt-1 mb-8">
        Colorizing Grayscale Images
      </h1>
      <p className="leading-6 mb-3">
        Colorizing grayscale images is a task where deep learning has proven to
        be usefull, even if the results are often far from truthfull. During a
        course in deep learning I got the oportunity to research this topic
        together with three other students at KTH. Together, we implemented an
        algorithm by R. Zhang et al. described in this paper. The goal of their
        model is not to produce ground truth colors. Instead, they aim to output
        a plausible colored picture that does not look artificially made. The
        algorithm consists of a convolutional neural network (CNN), trained by
        discretizing the dataset colorspace. We used their technique and trained
        the model on portrait images, provided by the dataset Labeled Faces in
        the Wild.
      </p>
      <div className={"grid grid-cols-4 gap-4 max-w-3xl m-auto my-8"}>
        <div className={imageContainerStyle}>
          <img src={in1} alt={"Original color"} className={imageStyle} />
        </div>
        <div className={imageContainerStyle}>
          <img src={in2} alt={"Original color"} className={imageStyle} />
        </div>
        <div className={imageContainerStyle}>
          <img src={in3} alt={"Original color"} className={imageStyle} />
        </div>
        <div className={imageContainerStyle}>
          <img src={in4} alt={"Original color"} className={imageStyle} />
        </div>
        <div class="col-start-1 col-end-5 text-center text-gray-500 italic">
          Original color images
        </div>
        <div className={imageContainerStyle}>
          <img src={out1} alt={"Colorized by CNN"} className={imageStyle} />
        </div>
        <div className={imageContainerStyle}>
          <img src={out2} alt={"Colorized by CNN"} className={imageStyle} />
        </div>
        <div className={imageContainerStyle}>
          <img src={out3}  alt={"Colorized by CNN"} className={imageStyle} />
        </div>
        <div className={imageContainerStyle}>
          <img src={out4} alt={"Colorized by CNN"} className={imageStyle} />
        </div>
        <div class="col-start-1 col-end-5 text-center text-gray-500 italic">
          Grayscale images colorized by the CNN
        </div>
      </div>
      <p className="leading-6 mb-3">
        We here impressed to see that the colorized images produced by our model
        was very convincing. Through a qualitative study we made, it was shown
        that the images colorized by the model were more convincing than the
        same portrait images colorized by the original model in 58% of the
        cases. To learn more about this project and the implemented model, read
        our <a href={reportPdf} className={'text-blue-500 hover:underline'}>full report here.</a>
      </p>

      {/* <div>
        <Document file={reportPdf} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </div> */}
    </div>
  );
};
